import React from 'react'

import '../style.scss'

function Saturday({id}) {
    const screenWidth = window.screen.width;
    let small = screenWidth < 768;

    return (
    <div className='agenda-stage' id={id}>
        <div className='agenda-stage__header'>
          {small ? <h1 className='agenda-stage__header__title'>Saturday,<br/> September 3rd</h1> : <h1 className='agenda-stage__header__title'>Saturday, September 3rd</h1>}
          <p className='agenda-stage__header__subtitle'>MC Stephan Livera</p>
        </div>
        <div className='agenda-stage__block'>
            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue first'>
                <h3 className='agenda-stage__block__items__item__header__text'>10:00</h3>
                {small && <span className='agenda-stage__block__items__item__header__button'>Welcome</span>}
              </div>
              <div className='agenda-stage__block__items__item__text '>
                <p className='agenda-stage__block__items__item__text__title'>Opening words</p>
              </div>
              <div className='agenda-stage__block__items__item__text first'>
                <p className='agenda-stage__block__items__item__text__title'>Max Kei</p>
              </div>
            </div>
            
            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>10:10</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/1Hx3xyFtujjTNACXBkSgCf?start=9m50s" target={'_blank'}>Money laundering is beautiful</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Giacomo Zucco</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>10:35</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/1Hx3xyFtujjTNACXBkSgCf?start=35m20s" target={'_blank'}>Fiat Delenda Est</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Jimmy Song</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/1Hx3xyFtujjTNACXBkSgCf?start=53m15s" target={'_blank'}>Bitcoin is a layered cake</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Lisa Neigut </p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:20</h3>
                <span className='agenda-stage__block__items__item__header__button'>Panel</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/1Hx3xyFtujjTNACXBkSgCf?start=1h13m20s" target={'_blank'}>Sidechains & L2</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Aaron Van Wirdum</p>

              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Adam Back, Paul Sztorc,<br/> Lisa Neigut, Obi Nwosu</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:55  coffee break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>12:20</h3>
                <span className='agenda-stage__block__items__item__header__button'>Panel</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/1Hx3xyFtujjTNACXBkSgCf?start=2h42m5s" target={'_blank'}>Bitcoin VC's</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Stephan Livera</p>

              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Grant Gilliam, <br/> Christopher Calicott,<br/> Andi, Michael Jarmuz</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>12:50</h3>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/1Hx3xyFtujjTNACXBkSgCf?start=2h42m5s" target={'_blank'}>Announcements</a>
                </p>

              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Blockstream, Jan3</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>1 on 1</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                <a href="https://bitcointv.com/w/1Hx3xyFtujjTNACXBkSgCf?start=2h52m" target={'_blank'}>1 on 1</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Marty Bent</p>

              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>John Carvalho, Paul Sztorc</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:25</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/1Hx3xyFtujjTNACXBkSgCf?start=3h15m30s" target={'_blank'}>Gold, Bitcoin, and CBDCs</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Matthew Mezinskis</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:45</h3>
                <span className='agenda-stage__block__items__item__header__button'>PANEL</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/1Hx3xyFtujjTNACXBkSgCf?start=3h36m" target={'_blank'}>Privacy</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Vlad Costea</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Riccardo Masutti, WizardofAus,<br/> Adam Fiscor, Viktor Vecsei,<br/> Kristaps Kaupe</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>14:10  Lunch break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>PANEL</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/63LBR3DWwhZyBggTRfKb9s" target={'_blank'}>Mining</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Marty Bent</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Adam Back, Thomas Pacchia,<br/> Alex Petrov, Braiins</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:30</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/63LBR3DWwhZyBggTRfKb9s?start=39m30s" target={'_blank'}>The lending is dead, long live the lending!</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Max Kei</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>QnA</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/63LBR3DWwhZyBggTRfKb9s?start=57m20s" target={'_blank'}>QnA </a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Daniel Prince</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Michael Saylor</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:30  Break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:45</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/63LBR3DWwhZyBggTRfKb9s?start=1h44m30s" target={'_blank'}>Removing Digital Intermediaries</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Chase Perkins </p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>17:15</h3>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/63LBR3DWwhZyBggTRfKb9s?start=2h13m20s" target={'_blank'}>QnA</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>hosted by Marty Bent, RHR Live show day 1</p>

              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Jack Mallers </p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>18:15</h3>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Closing Remarks </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Max Kei</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black last full'>
                <h3 className='agenda-stage__block__items__item__header__text'>18:20  afterparty</h3>
              </div>
            </div>

        </div>
    </div>
    )
}

export default Saturday
