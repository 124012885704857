import React from 'react'

import videoMP4Url from './../../assets/video/video.mp4'
import videoWebmUrl from './../../assets/video/video.webm'
import posterUrl from './../../assets/video/poster.jpg'

// Safari on iOS dont set muted attr via React, try use innerHTML
const getVideoTemplate = (poster, webm, mp4) => `
    <video class="promo-video__fullscreen" poster="${poster}" autoPlay loop muted playsinline webkit-playsinline> 
        <source src="${mp4}" type="video/mp4" />
        <source src="${webm}" type="video/webm" />
    </video>
`

function PromoVideo() {
    return (
        <section className="promo-video">
            <div className="promo-video__fullscreen" dangerouslySetInnerHTML={{__html: getVideoTemplate(posterUrl, videoWebmUrl, videoMP4Url)}} />
        </section>
    )
}

export default PromoVideo
