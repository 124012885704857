import React from 'react'
// import september2 from './../../assets/images/september2.png'
import september2 from './../../assets/images/Metamouse_logo.png'


import './style.scss'

const SecondDay = (props) => {
  const screenWidth = window.screen.width;
  let small = screenWidth < 768;

  return (
     <div className='days-blocks' {...props}>
        <div className='days-blocks__header'>
          {small ? <h1 className='days-blocks__header__title'>Layer 2 day,<br/> September 2nd</h1> : <h1 className='days-blocks__header__title'>Layer 2 day, September 2nd</h1>}
          {small ? <p className='days-blocks__header__subtitle'>2nd Layer technologies presented <br /> by the top industry companies</p> : <p className='days-blocks__header__subtitle'>2nd Layer technologies presented by the top industry companies</p>}
        </div>
        <div className='days-blocks__block'>
          <div className='days-blocks__block__head red'>
            <h2 className='days-blocks__block__head__title small-title'>Liquid, Lightning, RSK</h2>
            <div className='days-blocks__block__head__subtitle_block'>
            <p className='days-blocks__block__head__subtitle_block__item diamond'>by <a href='https://metamouse.cc/' className='whiteColorLink' target='_blank'>Metamouse</a></p>
              <p className='days-blocks__block__head__subtitle_block__item clock'>10:30 - 19:00</p>
              <p className='days-blocks__block__head__subtitle_block__item ticket'>BH2022 ticket holders</p>
              <p className='days-blocks__block__head__subtitle_block__item location'>Fantadroms</p>
            </div>
            {/* {!small && <button className='button button__black'><a target='_blank' rel="noreferrer" href='https://g.page/fantadroms-events?share'>GET Directions</a></button>} */}
            {!small && <img className='bottom-illustration' src={september2} alt='LightLiquide' />}
          </div>
          <div className='days-blocks__block__info'>
            <p className='days-blocks__block__info__title'>Participants</p>
            <div className='days-blocks__block__info__columns'>
              <div className='days-blocks__block__info__columns__column'>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://blockstream.com/' target='_blank' rel="noreferrer">Blockstream</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://bolt.observer/' target='_blank' rel="noreferrer">Bolt Observer</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://geyser.fund/' target='_blank' rel="noreferrer">geyser.fund</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://amboss.space/' target='_blank' rel="noreferrer">Amboss</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://voltage.cloud/' target='_blank' rel="noreferrer">Voltage</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://metamouse.cc/' target='_blank' rel="noreferrer">Metamouse</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://mempool.space/' target='_blank' rel="noreferrer">mempool.space</a></p>
              </div>
              <div className='days-blocks__block__info__columns__column'>
              <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://www.lnp-bp.org/' target='_blank' rel="noreferrer">LNP/BP Standards Association</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://twitter.com/mikejarmuz' target='_blank' rel="noreferrer">Mike Jarmuzz</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://revault.dev/' target='_blank' rel="noreferrer">Revault</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://twitter.com/eyeofsatoshi' target='_blank' rel="noreferrer">The Eye of Satoshi</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://lnbits.com/' target='_blank' rel="noreferrer">LNBits</a></p>
                <p className='days-blocks__block__info__content-big no-padding listItem margin-sm'><a href='https://blockstream.com/liquid/' target='_blank' rel="noreferrer">Liquid Network</a></p>
              </div>
            </div>
            {small && <div className='days-blocks__block__buttons'> <button className='button button__black'><a rel="noreferrer" target='_blank' href='https://g.page/fantadroms-events?share'>GET Directions</a></button></div>}
          </div>
        </div>
    </div>
  )
}

export default SecondDay