/* eslint-disable no-useless-escape */
import React, { useState, useMemo } from 'react'
import Button from '../Button/Button'
import useEmailSend from '../../hooks/useEmailSend'
import './style.scss'

const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const isValidEmail = (value) => regex.test(String(value).toLowerCase())

const successContent = (
    <div className="subscribe__success">You have subscribed to the newsletter. <br/> Stay tuned for updates!</div>
)

const sendError = (
    <span className="subscribe__error">Something went wrong! Try again later.</span>
)

function getErrorMessage(email, valid) {
    if (!valid) {
        return 'Incorrect email'
    }

    if (!email) {
        return 'Email cannot be empty'
    }

    return ''
}

function SignInForConference({ title }) {
    let path = window.location.pathname;
    const isMain = path === '/'
    const [email, setEmail] = useState('');
    const [isEmailDirty, setIsEmailDirty] = useState(false);
    const [sendStatus, sendEmail] = useEmailSend()
    const isEmailValid = useMemo(() => isValidEmail(email), [email])
    const errorMessage = useMemo(() => getErrorMessage(email, isEmailValid), [email, isEmailValid])
    const onChange  = (e) => {
        setEmail(e.target.value)
    }

    const onBlur = () => {
        if (!isEmailDirty) {
            setIsEmailDirty(true)
        }
    }

    const onFocus = () => {
        setIsEmailDirty(false)
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (isEmailValid) {
            await sendEmail(email);
            setEmail('');
        }
    }

    const onSubmitClick = (e) => {
        e.stopPropagation();
    }

    const content = (
        <>
            <label htmlFor="email" className="subscribe__label"/>
            <input
                type="email"
                id="email"
                name="email"
                className="subscribe__input"
                placeholder="Enter your email"
                autoComplete="disabled"
                required
                value={email}
                maxLength={50}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            <Button disabled={!isEmailValid} onClick={onSubmitClick} type="submit"
                    title={isMain ? "SUBSCRIBE" : "Register"}/>
        </>
    )

    const validationError = isEmailDirty && <span className="subscribe__error">{errorMessage}</span>

    return (
        <form className="subscribe" autoComplete="disabled" action={path} method="POST" onSubmit={onSubmit}>
            <fieldset className="subscribe__fieldset">
                <h2 className="subscribe__title">{title}</h2>
                <div className="subscribe__wrap">
                    {sendStatus === true ? successContent : content}
                </div>
                <span className="subscribe__error-field">
                    {sendStatus !== true && validationError}
                    {sendStatus === false && sendError}
                </span>
            </fieldset>
        </form>
    )
}

export default SignInForConference
