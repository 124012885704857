import React from 'react'
import YoutubeEmbed from './../YoutubeEmbed/YoutubeEmbed';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/scrollbar";

import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]);

function VideoSlider() {
    const videos = [
        {
            embedId: 'Snx9Y1f0qf8',
            title: 'Baltic HoneyBadger 2019',
        },
        {
            embedId: 'kQ68GaLw7uk',
            title: 'Baltic HoneyBadger 2018',
        },
        {
            embedId: '1pW4CowQdpE',
            title: 'Baltic HoneyBadger 2017',
        },
        {
            embedId: '3uqZFwcZWw4',
            title: 'Baltic HoneyBadger 2017',
        }
    ]

    return (
        <div className="swiper-block">
            <Swiper
                navigation={true}
                slidesPerView={1}
                spaceBetween={30}
                grabCursor={true}
                simulateTouch={true}
                breakpoints={{
                    1024: {
                        slidesPerView: 'auto',
                    },
                }}
                className="mySwiper">

                {videos.map(video => (
                    <SwiperSlide key={video.embedId}>
                        <div className="swiper__video">
                            <YoutubeEmbed embedId={video.embedId}/>
                            <h3 className="swiper__description">{video.title}</h3>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default VideoSlider
