import React, { useState } from 'react'
import Sunday2nd from './2ndstage/Sunday2nd';
import Saturday2nd from './2ndstage/Saturday2nd'
import Saturday from './mainstage/Saturday'
import Sunday from './mainstage/Sunday'
import separatorUrl from './../../assets/images/sponsors-icons/separator.svg'


import './style.scss'
import clsx from 'clsx';

function Agenda() {
    const screenWidth = window.screen.width;
    let small = screenWidth < 768;

    let [activeStage, setActiveStage] = useState('mainstage')

    const items = [
        { title: 'Bitcoin stage', id: 'mainstage' },
        { title: 'Sats stage', id: '2ndstage' },
    ]

    const handleChangeStage = (item) => {
        setActiveStage(item)
    } 

    const [showSelect, setShowSelect] = useState(false);
    const [option, setOption] = useState("Select Stage")

    const handleOptionClick = () => {
        setShowSelect(!showSelect)
    }

    const onItemClickHandler = (item) => {
        setActiveStage(item.id)

        setOption(item.title)

        setTimeout(() => {
         setShowSelect(false)
     }, 100)
    }

    return (
        <>
        <div id='mainstage'></div>
        <div id='2ndstage'></div>
        <div className="page agenda">
           
            <div className="agenda__header">
                <h1 className="agenda__title">Conference agenda</h1>
                <p className="agenda__subtitle">Switch between two stages to see the agenda for both conference days</p>
            </div>

            <div className="agenda__container">
                {
                    small && 
                    <div id="month" className="select">
                        <div id="show-options" onClick={handleOptionClick} className="select-option">{option}</div>
                        <div id="options-list" className={clsx('select-options', {
                         visible: showSelect
                        })}>
                        {items.map(item => (
                            <div key={item.id} className="select-item" onClick={() => onItemClickHandler(item)}>{item.title}</div>
                         ))}
                        </div>
                    </div>
                }

                <div className="multi-toggle-container fixed" id="data">
                    {items.map(item => (
                        <a
                            key={item.id}
                            href={`#${item.id}`}
                            className={clsx("multi-toggle-item", {
                            active: item.id === activeStage
                            })}
                            onClick={() => handleChangeStage(item.id)}
                        >
                            {item.title}
                        </a>
                    ))}
                </div>

                <div className={activeStage === 'mainstage' ? 'activeStage' : 'disableStage'}>
                    <Saturday />
                    <img src={separatorUrl} alt="separator" srcSet="" className="separator"/>
                    <Sunday />
                </div>
            
                <div className={activeStage === '2ndstage' ? 'activeStage' : 'disableStage'}>
                    <Saturday2nd />
                    <img src={separatorUrl} alt="separator" srcSet="" className="separator"/>
                    <Sunday2nd />
                </div>
            </div>
        </div>
        </>
    )
}

export default Agenda
