import React from 'react'
import './style.scss'

const Banner = React.memo(({children}) => {


    return (
        <section className="banner banner--js">
            <h2 className="banner__text">
                {children}
            </h2>
        </section>
    )
})

export default Banner
