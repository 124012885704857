import React, { useEffect } from 'react'
import KeyPlayersUrl from './../../assets/images/european-bitcoin-conference.jpg'
import KeyPlayersUrlWebp from './../../assets/images/european-bitcoin-conference.webp'
import BitcoinWeekUrl from './../../assets/images/riga-bitcoin-conference.jpg'
import BitcoinWeekUrlWebp from './../../assets/images/riga-bitcoin-conference.webp'
import BitcoinOnlyUrl from './../../assets/images/bitcoin-conference.jpg'
import BitcoinOnlyUrlWebp from './../../assets/images/bitcoin-conference.webp'

import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./style.scss"

function Plan() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo("#plans", {
            autoAlpha: 0,
            rotationY: 45,
        },
        {
            duration: .6,
            autoAlpha: 1,
            rotationY: 0,
            scrollTrigger: {
                trigger: ".swiper-block",
                start: "top, +=200",
                end: "+=200",
                toggleActions: 'play none none reverse'
            }
        });
    }, []);

    return (
        <section className="plans" id="plans">
            <div className="plans__block block">
                <picture>
                    <source srcSet={BitcoinOnlyUrlWebp} type="image/webp" />
                    <img src={BitcoinOnlyUrl} alt="Bitcoin conference" className="block__image" width="424"/>
                </picture>
                <h2 className="block__title">Bitcoin-only</h2>
                <p className="block__text"> Important, popular, and most controversial topics in Bitcoin</p>

                {/* <Link to="/agenda" className="block__link" alt="Agenda">
                    <p className="agenda-list__link">Agenda</p>
                    <img src={arrowUrl} width="24" height="24" alt="Agenda" className="agenda-list__image"/>
                </Link> */}
            </div>
            <div className="block">

                <picture>
                    <source srcSet={KeyPlayersUrlWebp} type="image/webp" />
                    <img src={KeyPlayersUrl} alt="European Bitcoin Conference" className="block__image" width="424"/>
                </picture>
                <h2 className="block__title">Key players</h2>
                <p className="block__text">The best networking you could get from top people in the industry</p>

                {/* <Link to="/speakers" className="block__link" alt="Agenda">
                    <p className="agenda-list__link">Speakers</p>
                    <img src={arrowUrl} width="24" height="24" alt="Speakers" className="agenda-list__image"/>
                </Link> */}
            </div>
            <div className="block">
                <picture>
                    <source srcSet={BitcoinWeekUrlWebp} type="image/webp" />
                    <img src={BitcoinWeekUrl} alt="Riga Bitcoin Conference" className="block__image" width="424"/>
                </picture>

                <h2 className="block__title">Riga Bitcoin Week 2022</h2>
                <p className="block__text">A whole week of workshops, meetups, and social gatherings with bitcoiners from all over the world</p>

                {/* <Link to="/week" className="block__link" alt="Agenda">
                    <p className="agenda-list__link">Bitcoin week</p>
                    <img src={arrowUrl} width="24" height="24" alt="Bitcoin Week" className="agenda-list__image"/>
                </Link> */}
            </div>
        </section>
    )
}

export default Plan
