import React from 'react'
import digitalBrandingUrl from './../../assets/images/sponsors-icons/digital-branding.svg'
import digitalTicketUrl from './../../assets/images/sponsors-icons/digital-ticket-branding.svg'
import liveStreamUrl from './../../assets/images/sponsors-icons/lifestream.svg'
import exclusiveSmallUrl from './../../assets/images/sponsors-icons/exclusive-line.svg'
import soldOutUrl from './../../assets/images//sponsors-icons/sold_out.svg'
import './style.scss'

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import clsx from 'clsx'


function DigitalSponsorship(props) {
    const digitalSponsorships = [
        {
            title: 'Digital Branding',
            img: digitalBrandingUrl,
            price: '$3 000',
            benefits: 'Benefits',
            color: 'blue',
            sold: '',
            class: 'hidden',
            texts: [
                {one: 'Your brand featured during Livestream'},
                {two: 'Website branding'},
            ],
        },
        {
            title: 'Livestrem sponsorship',
            img: liveStreamUrl,
            price: '$5 000',
            benefits: 'Benefits',
            color: 'red',
            sold: '',
            class: '',
            texts: [
                {one: 'Your brand featured during Livestream'},
                {two: 'Your short promo video during Livestream'},
                {three: 'Website branding'},
            ],
        }
    ]

    const digitalSponsorshipsSlides = () => digitalSponsorships.map(sponsorship => (
        <div className={clsx("inner-card", sponsorship?.class, sponsorship?.sold)} key={sponsorship.title}>
            <div className="sold_out">
                <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
                <div className={clsx("inner-card__header", sponsorship.color)}>
                    <h3 className="card__title">{sponsorship.title}</h3>
                    <img src={sponsorship.img} alt="" />
                </div>
                <div className="card__body">
                <h3 className="card__money">{sponsorship.price}</h3>
                <p className="card__list-title">{sponsorship.benefits}</p>
                    <ul className="card__list">
                    {sponsorship.texts?.map(text => (
                            <li key={text.one} className="card__elem">{text?.one || text?.two || text?.three }</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
     ))

    const screenWidth = window.screen.width;
    let small = screenWidth < 767;

  return (
    <div className="sponsors-block" {...props}>
        <h2 className="sponsors-block__title">Digital Sponsorship</h2>
        <p className="sponsors-block__subtitle">Your company featured in BH digital environment</p>

        {
            small ? (
                <Swiper
                    navigation={false}
                    pagination={{ clickable: true }}
                    modules={[Pagination, A11y]}
                    slidesPerView={1}
                    spaceBetween={30}
                    grabCursor={true}
                    className="mySwiper"
                    >
                    {digitalSponsorships.map(sponsorship => (
                        <SwiperSlide>
                            <div className={clsx("inner-card", sponsorship?.class, sponsorship?.sold)} key={sponsorship.title}>
                                <div className="sold_out">
                                    <img src={soldOutUrl} alt="" />
                                </div>
                                <div className="element">
                                    <div className={clsx("inner-card__header", sponsorship.color)}>
                                        <h3 className="card__title">{sponsorship.title}</h3>
                                        <img src={sponsorship.img} alt="" />
                                    </div>
                                    <div className="card__body">
                                        <h3 className="card__money">{sponsorship.price}</h3>
                                        <p className="card__list-title">{sponsorship.benefits}</p>
                                        <ul className="card__list">
                                            {sponsorship.texts?.map(text => (
                                                <li key={text.one} className="card__elem">{text?.one || text?.two || text?.three || text?.four }</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <div className="inner-cards">
                    {digitalSponsorshipsSlides()}
                </div>
            )
        }

    </div>
  )
}

export default DigitalSponsorship
