import React from 'react'
import Button from '../../components/Button/Button'
import diamondsm from './../../assets/images/diamondsm.png'

import './style.scss'


const ThirdDay = (props) => {
  const screenWidth = window.screen.width;
  let small = screenWidth < 768;

  return (
    <div className='days-blocks' {...props}>
        <div className='days-blocks__header'>
          {small ? <h1 className='days-blocks__header__title'>Baltic Honeybadger,<br/> September 3rd & 4th</h1> : <h1 className='days-blocks__header__title'>Baltic Honeybadger, September 3rd & 4th</h1>}
        </div>
        <div className='days-blocks__block'>
          <div className='days-blocks__block__head black'>
            <h2 className='days-blocks__block__head__title small-title'>Baltic Honeybadger<br/> Conference</h2>
            <div className='days-blocks__block__head__subtitle_block'>
              <p className='days-blocks__block__head__subtitle_block__item white clock'>10:00 - 18:00</p>
              <p className='days-blocks__block__head__subtitle_block__item white location'>Fantadroms</p>
            </div>
            {/* {!small && <Button title="Buy tickets" />} */}
            {/* {!small && <button className='button button__black button__second'><a rel="noreferrer" target='_blank' href='https://g.page/fantadroms-events?share' >GET Directions</a></button>} */}
            {small && <img className='bottom-illustration' src={diamondsm} alt='LightLiquide' /> }
          </div>
          <div className='days-blocks__block__info'>
            <p className='days-blocks__block__info__title'>BH2022 Conference  - Day 1</p>
            {small ? <p className='days-blocks__block__info__content'>The first day of the conference will cover presentations and panels about the most critical topics for Bitcoin’s development, host an open microphone stage where the new ideas will be born and presented and will end with an afterparty where all attendees will have a chance to meet our speakers in an informal atmosphere.</p> : <p className='days-blocks__block__info__content'>The first day of the conference will cover presentations and panels about the most critical<br/> topics for Bitcoin’s development, host an open microphone stage where the new ideas will<br/> be born and presented and will end with an afterparty where all attendees will have a<br/> chance to meet our speakers in an informal atmosphere.</p>}
            <div className='days-blocks__block__info__line'></div>
            <p className='days-blocks__block__info__title'>BH2022 Conference - Day 2</p>
            {small ? <p className='days-blocks__block__info__content'>The second day of the conference will become a culmination of Riga Bitcoin Week 2022, which will highlight the essential intakes and ideas, hosting some of your favorite speakers during panels and fireside chats.</p> : <p className='days-blocks__block__info__content'>The second day of the conference will become a culmination of Riga Bitcoin Week 2022, <br/>which will highlight the essential intakes and ideas, hosting some of your favorite speakers<br/> during panels and fireside chats.</p>}
            {small && <div className='days-blocks__block__buttons'>
                {/* <Button title="Buy tickets" /> */}
                <button className='button button__black button__second'><a rel="noreferrer" target='_blank' href='https://g.page/fantadroms-events?share' >GET Directions</a></button>
              </div>}
          </div>
        </div>
    </div>
  )
}

export default ThirdDay
