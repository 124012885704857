import React from 'react'
import sponsors from '../../sponsors.json';
import './../Partners/style.scss'

const IMAGE_URI = '/images/sponsors/';

function Sponsors() {

    let sponsorItem = sponsors.map(sponsor => {
        const url = IMAGE_URI + sponsor.image;

        return (
            <div key={sponsor.id} id={sponsor.id}>
                <a href={sponsor.url} target="_blank" key={sponsor.id} id={sponsor.id} className="partners__logo" rel="noreferrer">
                    <img src={url} alt={sponsor.image} className="partner__image"/>
                </a>
            </div>
        )
    })
  return (
    <div className="partners__logos">
        {sponsorItem}
    </div>
  )
}

export default Sponsors
