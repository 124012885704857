import React from 'react'
import './style.scss'
import MultiToggle from './../../components/MultiToggle/MultiToggle'
import EventSlider from './EventSlider';
import MainBlock from './MainBlock';
import GoodieSlider from './GoodieSlider';
import ExhibitorBooth from './ExhibitorBooth';
import DigitalSponsorship from './DigitalSponsorship'
import ConferenceFacilities from './ConferenceFacilities'
import separatorUrl from './../../assets/images/sponsors-icons/separator.svg'
import sponsorsNoteUrl from './../../assets/images/sponsors-icons/sponsors-note.svg'

function OfferForSponsors() {
    // const [windowWidth, setWindowWidth] = useState(0);
    // const [windowHeight, setWindowHeight] = useState(0);
    //
    // let resizeWindow = () => {
    //   setWindowWidth(window.innerWidth);
    //   setWindowHeight(window.innerHeight);
    // };
    //
    // useEffect(() => {
    //   resizeWindow();
    //   window.addEventListener("resize", resizeWindow);
    //   return () => window.removeEventListener("resize", resizeWindow);
    // }, []);

    const screenWidth = window.screen.width;
    let small = screenWidth < 767;


    return (
        <div className="page sponsors">
            {small ?
                <h1 className="sponsors__title">2022<br/>Sponsorship<br/> tiers </h1>
                :
                <h1 className="sponsors__title">2022 Sponsorship tiers</h1>
            }
            <p className="sponsors__subtitle general">Baltic Honeybadger offers a variety of sponsorship options
                depending on your business goal and budget </p>
            <div className="offers">
                <MultiToggle
                    title='Select sponsor options'
                    items={[
                        { title: 'Main Sponsors', id: 'main' },
                        { title: 'Exhibitor Booths', id: 'booth' },
                        { title: 'Digital Sponsorship', id: 'digital' },
                        { title: 'Conference Facilities', id: 'conference' },
                        { title: 'On-site Branding', id: 'other' },
                        { title: 'Events', id: 'events' },
                    ]}
                />

                <MainBlock id="main"/>

                <img src={separatorUrl} alt="separator" srcSet="" className="separator"/>

                <ExhibitorBooth id="booth"/>

                <img src={separatorUrl} alt="separator" srcSet="" className="separator"/>

                <DigitalSponsorship id="digital"/>

                <img src={separatorUrl} alt="separator" srcSet="" className="separator"/>

                <ConferenceFacilities id="conference"/>

                <img src={separatorUrl} alt="separator" srcSet="" className="separator"/>

                <GoodieSlider id="other"/>

                <img src={separatorUrl} alt="separator" srcSet="" className="separator"/>

                <EventSlider id="events"/>
            </div>
            <div className="sponsors__note">
                    <img src={sponsorsNoteUrl} alt="sponsors note" srcSet="" />
                    <p className='sponsors__note__text'>Prices do not include VAT</p>
            </div>
        </div>
    )
}


export default OfferForSponsors
