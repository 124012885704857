import React from 'react'

import BHBannerUrl from './../../assets/images/bh2022.png';
import BHBannerSmallUrl from './../../assets/images/bh2022-small.png';

function BlueBanner() {

    const screenWidth = window.screen.width;
    let small = screenWidth < 1201;

    return (
        <img src={small ? BHBannerSmallUrl : BHBannerUrl} alt="Bh2022" className="blue-banner" id="bluebannerjs"/>
    )
}

export default BlueBanner
