import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

import speakers from '../../speakers.json';
import arrowUpUrl from './../../assets/images/arrow-up.svg';

import './style.scss'
import gsap from 'gsap'
import { ScrollTrigger } from "../../assets/js/ScrollTrigger";

const IMAGE_URI = '/images/speakers/'


const SpeakersList = React.memo(() => {

    let path = window.location.pathname;
    let speakersItem;

    if (path === '/') {
        speakersItem = speakers.filter(item => item.main === true).map(speaker => {

            const url = IMAGE_URI + speaker.image;
            return (
                <figure key={speaker.id} id={speaker.id} className="person">
                    <a href={speaker.twitUrl} className="person__link" target="_blank" rel="noreferrer">
                        <img src={url} alt={speaker.image} className="person__image"/>
                        {speaker.remotely && <div className='person__remotely'>Remotely</div>}
                    </a>
                    <figcaption>
                        <h2 className="block__title">{speaker.name}</h2>
                        <p className="block__text">{speaker.title}</p>
                    </figcaption>
                </figure>
            )
        })
    } else {
        speakersItem = speakers.map(speaker => {

            const url = IMAGE_URI + speaker.image;
            return (
                <figure key={speaker.id} id={speaker.id} className="person">
                    <a href={speaker.twitUrl} className="person__link" target="_blank" rel="noreferrer">
                        <img src={url} alt={speaker.image} className="person__image"/>
                        {speaker.remotely && <div className='person__remotely'>Remotely</div>}
                    </a>
                    <figcaption>
                        <h2 className="block__title">{speaker.name}</h2>
                        <p className="block__text">{speaker.title}</p>
                    </figcaption>
                </figure>
            )
        })
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo("#persons", { autoAlpha: 0, },
        { duration: .6, autoAlpha: 1,
            scrollTrigger: {
                trigger: "#plans",
                start: "top, +=200",
                end: "+=200",
                toggleActions: 'play none none reverse'
            }
        });
    }, []);

    return (
        <section className="persons" id="persons">
            <div className="persons__wrap">
                {speakersItem}
                { path === '/' &&
                    <Link to="/speakers" className="persons__wrapper">
                        <p className="persons__link"> All Speakers </p>
                        <img src={arrowUpUrl} alt="Speakers" className="persons__image"/>
                    </Link>
                }
            </div>

        </section>
    )
})

export default SpeakersList

