import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import BH2018Webp from './../../assets/images/sites/baltic-honeybadger-2018.webp'
import BH2018Jpg from './../../assets/images/sites/baltic-honeybadger-2018.jpg'

import BH2017Webp from './../../assets/images/sites/baltic-honeybadger-2017.webp'
import BH2017Jpg from './../../assets/images/sites/baltic-honeybadger-2017.jpg'

import BH2019Webp from './../../assets/images/sites/baltic-honeybadger-2019.webp'
import BH2019Jpg from './../../assets/images/sites/baltic-honeybadger-2019.jpg'

function ArticleSlider() {
    const slides = [
        {
            title: 'BH2019',
            alt: 'Baltic Honeybadger 2019',
            webp: BH2019Webp,
            img: BH2019Jpg,
            url: 'https://2019.baltichoneybadger.com'
        },
        {
            title: 'BH2018',
            alt: 'Baltic Honeybadger 2018',
            webp: BH2018Webp,
            img: BH2018Jpg,
            url: 'https://2018.baltichoneybadger.com'
        },
        {
            title: 'BH2017',
            alt: 'Baltic Honeybadger 2017',
            webp: BH2017Webp,
            img: BH2017Jpg,
            url: 'https://www.youtube.com/watch?v=DHc81OL_hk4&t=1s'
        }
    ]

    return (
        <div className="article-swiper">
            <Swiper
                navigation={false}
                modules={[Pagination]}
                slidesPerView={1}
                spaceBetween={30}
                grabCursor={true}
                pagination={{ clickable: true }}
                breakpoints={{
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                className="mySwiper">
                {slides.map((slide) => (
                    <SwiperSlide key={slide.url}>
                        <div className="block">
                            <a href={slide.url} target="_blank" rel="noreferrer">
                                <picture>
                                    <source srcSet={slide.webp} type="image/webp"/>
                                    <img src={slide.img} alt={slide.alt} className="block__image"/>
                                </picture>
                            </a>
                            <h2 className="block__title">{slide.title}</h2>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default ArticleSlider
