import React, { useEffect } from 'react'

import Button from './../Button/Button'
import Banner from '../Banner/Banner'

import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

import './style.scss'

function ComplexBanner() {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo("#complex-banner", {
            autoAlpha: 0,
            x: -400,
        },
        {
            duration: .6,
            autoAlpha: 1,
            x: 0,
            scrollTrigger: {
                trigger: "#plans",
                start: "top, +=700",
                end: "bottom, +=900",
                toggleActions: 'play none none none'
            }
        });
    }, []);

    return (
        <section className="complex-banner" id="complex-banner">
            <div className="banner-wrap">
                <Banner>
                    <span className="comic-text comic-text--speakers">high-profile speakers</span> from all around the world
                </Banner>
                <div className="complex-banner__wrap">
                    {/* <Button title="REGISTER"/> */}
                </div>
            </div>
        </section>
    )
}

export default ComplexBanner
