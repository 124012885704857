const loadedUrls = []
const $$head = document.head

const createScript = (url, attrs = {}, onload, onerror) => {
    const script = document.createElement('script')

    Object.keys(attrs).forEach(name => {
        // @ts-ignore
        script.setAttribute(name, attrs[name])
    })

    script.async = true
    script.src = url

    script.onload = onload
    script.onerror = onerror

    return script
}

const loadAsync = (url, $$to = $$head, attributes = {}, once = false) =>
    new Promise((resolve, reject) => {
        if (once && loadedUrls.includes(url)) {
            resolve()
            return
        }

        if (once) {
            loadedUrls.push(url)
        }

        const script = document.createElement('script')

        Object.keys(attributes).forEach(name => {
            // @ts-ignore
            script.setAttribute(name, attributes[name])
        })

        script.async = true
        script.src = url

        script.onload = resolve
        script.onerror = reject

        $$to.appendChild(createScript(url, attributes, resolve, reject))
    })

export default loadAsync
