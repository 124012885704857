import React from 'react'
import goodieBagUrl from './../../assets/images/sponsors-icons/goodi-bag.svg'
import exclusiveSmallUrl from './../../assets/images/sponsors-icons/exclusive-line.svg'
import soldOutUrl from './../../assets/images//sponsors-icons/sold_out.svg'
import './style.scss'

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import clsx from 'clsx'


function GoodieSlider(props) {
    const goodies = [
        {
            title: 'Goodie bag drop',
            img: goodieBagUrl,
            color: 'blue',
            price: '$3 000',
            benefits: 'Benefits',
            sold: '',
            texts: [
                {one: 'Your custom material in all goodie bags'},
            ],
            exclusiveUrl: exclusiveSmallUrl
        },
        {
            title: 'Badge Branding',
            img: goodieBagUrl,
            color: 'red',
            price: '$5 000',
            benefits: 'Benefits',
            sold: 'sold',
            texts: [
                {one: 'Your logo on all attendees’ badges'},
            ],
            exclusiveUrl: exclusiveSmallUrl
        },
        {
            title: 'Seat drop',
            span: '(Open mic stage)',
            img: goodieBagUrl,
            color: 'blue',
            price: '$1 000',
            benefits: 'Benefits',
            sold: '',
            texts: [
                {one: 'Your custom material on the 2nd stage seats'},
            ],
            exclusiveUrl: exclusiveSmallUrl
        },
        {
            title: 'Seat drop',
            span: '(main stage)',
            img: goodieBagUrl,
            color: 'blue',
            price: '$2 500',
            benefits: 'Benefits',
            sold: '',
            texts: [
                {one: 'Your custom material on the main stage seats'},
            ],
            exclusiveUrl: exclusiveSmallUrl
        }
    ]

    const goodieSlides = () => goodies.map(goodie => (
        <div className={clsx("inner-card", goodie?.sold)} key={goodie.title}>
             <div className="sold_out">
                <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
                <div className={clsx("inner-card__header", goodie.color)}>
                    <h3 className="card__title">
                        {goodie.title}<br/>
                        {goodie.span}
                    </h3>
                    <img src={goodie.img} alt="" />
                </div>
                <img src={goodie?.exclusiveUrl} alt="" className="exclusive-border"/>
                <div className="card__body">
                    <h3 className="card__money">{goodie.price}</h3>
                    <p className="card__list-title">{goodie.benefits}</p>
                    <ul className="card__list">
                        {goodie.texts?.map(text => (
                            <li className="card__elem">{text?.one || text?.two }</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
     ))

    const screenWidth = window.screen.width;
    let small = screenWidth < 767;

  return (
    <div className="sponsors-block" {...props}>
        <h2 className="sponsors-block__title">On-site Branding</h2>
        <p className="sponsors-block__subtitle">Discover other opportunities to feature your brand on-site</p>

        {
            small ? (
                <Swiper
                    navigation={false}
                    pagination={{ clickable: true }}
                    modules={[Pagination, A11y]}
                    slidesPerView={1}
                    spaceBetween={30}
                    grabCursor={true}
                    className="mySwiper"
                >
                    {goodies.map(goodie => (
                        <SwiperSlide>
                            <div className={clsx("inner-card", goodie?.sold)} key={goodie.title}>
                                <div className="sold_out">
                                    <img src={soldOutUrl} alt="" />
                                </div>
                                <div className="element">
                                    <div className={clsx("inner-card__header", goodie.color)}>
                                        <h3 className="card__title">
                                            {goodie.title} <br/>
                                            {goodie.span}
                                        </h3>
                                        <img src={goodie.img} alt="" />
                                    </div>
                                    <img src={goodie?.exclusiveUrl} alt="" className="exclusive-border"/>
                                    <div className="card__body">
                                        <h3 className="card__money">{goodie.price}</h3>
                                        <p className="card__list-title">{goodie.benefits}</p>
                                        <ul className="card__list">
                                            {goodie.texts?.map(text => (
                                                <li className="card__elem">{text?.one || text?.two || text?.three || text?.four }</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <div className="inner-cards rows-2">
                    {goodieSlides()}
                </div>
            )
        }

    </div>
  )
}

export default GoodieSlider
