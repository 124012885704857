import React, { useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './style.scss'

const links = [
    {
        type: 'email',
        name: 'info@baltichoneybadger.com',
        link: 'mailto:info@baltichoneybadger.com',
    },
    {
        type: 'twitter',
        name: 'Twitter',
        link: 'https://twitter.com/hodlhodl',
    },
    {
        type: 'youtube',
        name: 'Youtube',
        link: 'https://www.youtube.com/c/HodlHodl',
    },
    {
        type: 'telegram',
        name: 'Telegram',
        link: 'https://t.me/baltichoneybadger',
    },
    {
        type: 'facebook',
        name: 'Facebook',
        link: 'https://www.facebook.com/events/969713230347009',
    },
]

const FooterLink = ({ item }) => {
    const onClick = useCallback((e) => {
        e.preventDefault();

        window.open(item.link, '_blank')
    }, [item.link])

    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <a className={`footer__link footer__link_${item.type}`} key={item.link} role="button" onClick={onClick}><span>{item.name}</span></a>
}

function Footer() {
    const { pathname } = useLocation()
    const year = pathname === '/register' ? '2023' : '2022'
    const titleClassName = year === '2022' ? 'footer__title' : 'footer__title-register'

    return (
        <footer className="footer">
            <h2 title="title" className={titleClassName}>{`Baltic HoneyBadger ${year}`}</h2>
            <div className="footer__container page-container">
                <div className="footer__wrap">
                    {links.map((item) => (
                        <FooterLink key={item.link} item={item} />
                    ))}
                </div>
            </div>
            <div className='footer__bottom'>
                <p className='footer__bottom__copyright'>© Baltic HoneyBadger Conference</p>
                <Link className='footer__bottom__terms' to="/terms-conditions">Terms and Conditions</Link>
            </div>
        </footer>
    )
}

export default Footer
