import React from 'react'
import diamondUrl from './../../assets/images/sponsors-icons/diamond.svg'
import exclusiveSmallUrl from './../../assets/images/sponsors-icons/exclusive-line.svg'
import soldOutUrl from './../../assets/images//sponsors-icons/sold_out.svg'
import './style.scss'

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import clsx from 'clsx'


function EventSlider(props) {
    const events = [
        {
            title: 'VIP BBQ',
            img: diamondUrl,
            price: '$5 000',
            benefits: 'Benefits',
            color: 'red',
            sold: 'sold',
            texts: [
                {one: 'Brand presence'},
                {two: 'VIP guests only'},
                {three: '2 invites'},
            ],
            exclusiveUrl: exclusiveSmallUrl
        },
        {
            title: 'Main Afterparty sponsor - day 1',
            img: diamondUrl,
            price: '$10 000',
            benefits: 'Benefits',
            color: 'black',
            sold: 'sold',
            texts: [
                {one: 'Brand presence'},
                {two: 'Branded drinks menu'},
                {three: 'Stage time'},
            ],
            exclusiveUrl: exclusiveSmallUrl
        },
        {
            title: 'VIP Speakers Preparty',
            img: diamondUrl,
            price: '$7 500',
            benefits: 'Benefits',
            color: 'black',
            sold: 'sold',
            texts: [
                {one: 'Brand presence'},
                {two: 'VIP guests only'},
                {three: '2 invites'},
            ],
            exclusiveUrl: exclusiveSmallUrl
        },
        {
            title: 'Afterparty sponsor - day 2',
            img: diamondUrl,
            price: '$3 500',
            benefits: 'Benefits',
            color: 'blue',
            sold: 'sold',
            texts: [
                {one: 'Brand presence'},
                {two: 'Your branded welcome drinks'},
            ],
            exclusiveUrl: exclusiveSmallUrl
        },
        {
            title: 'Exclusive VIP afterparty',
            img: diamondUrl,
            price: '$10 000',
            benefits: 'Benefits',
            color: 'black',
            sold: 'sold',
            texts: [
                {one: 'Brand presence'},
                {two: 'VIP guests only'},
                {three: '2 invites'},
            ],
            exclusiveUrl: exclusiveSmallUrl
        },
        {
            title: 'Bitcoin week Workshop',
            img: diamondUrl,
            price: '$7 500',
            benefits: 'Benefits',
            color: 'black',
            sold: '',
            texts: [
                {one: 'Host your workshop during Riga Bitcoin week'},
                {two: 'Organized for you'}
            ],
            exclusiveUrl: exclusiveSmallUrl
        },

    ]

    const eventSlides = () => events.map(event => (
        <div className={clsx("inner-card", event?.sold)} key={event.title}>
            <div className="sold_out">
                <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
                <div className={clsx("inner-card__header", event.color)}>
                    <h3 className="card__title">{event.title}</h3>
                    <img src={event.img} alt="" />
                </div>
                <img src={event?.exclusiveUrl} alt="" className="exclusive-border"/>
                <div className="card__body">
                    <h3 className="card__money">{event.price}</h3>
                    <p className="card__list-title">{event.benefits}</p>
                    <ul className="card__list">
                        {event.texts?.map(text => (
                            <li key={text.one} className="card__elem">{text?.one || text?.two || text?.three }</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    ))

    const screenWidth = window.screen.width;
    let small = screenWidth < 767;

  return (
    <div className="sponsors-block" {...props}>
        <h2 className="sponsors-block__title">Events</h2>
        <p className="sponsors-block__subtitle">Become the exclusive sponsor and an attendee of BH special events</p>
        {
            small ? (
                <Swiper
                    navigation={false}
                    pagination={{ clickable: true }}
                    modules={[Pagination, A11y]}
                    slidesPerView={1}
                    spaceBetween={30}
                    grabCursor={true}
                    className="mySwiper"
                    >
                    {events.map(event => (
                        <SwiperSlide>
                            <div className={clsx("inner-card", event?.sold)} key={event.title}>
                            <div className="sold_out">
                                <img src={soldOutUrl} alt="" />
                            </div>
                                <div className="element">
                                    <div className={clsx("inner-card__header", event.color)}>
                                        <h3 className="card__title">{event.title}</h3>
                                        <img src={event.img} alt="" />
                                    </div>
                                    <img src={event?.exclusiveUrl} alt="" className="exclusive-border"/>
                                    <div className="card__body">
                                        <h3 className="card__money">{event.price}</h3>
                                        <p className="card__list-title">{event.benefits}</p>
                                        <ul className="card__list">
                                            {event.texts?.map(text => (
                                                <li key={text.one} className="card__elem">{text?.one || text?.two || text?.three || text?.four }</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <div className="inner-cards rows-2">
                    {eventSlides()}
                </div>
            )
        }

    </div>
  )
}

export default EventSlider
