import React from 'react'

import '../style.scss'

function Saturday2nd({id}) {
    const screenWidth = window.screen.width;
    let small = screenWidth < 768;


    return (
        <div className='agenda-stage' id={id}>
        <div className='agenda-stage__header'>
          {small ? <h1 className='agenda-stage__header__title'>Saturday,<br/> September 3rd</h1> : <h1 className='agenda-stage__header__title'>Saturday, September 3rd</h1>}
          <p className='agenda-stage__header__subtitle'>MC Lunaticoin</p>
        </div>
        <div className='agenda-stage__block'>
            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue first'>
                <h3 className='agenda-stage__block__items__item__header__text'>10:30</h3>
                {small && <span className='agenda-stage__block__items__item__header__button'>Welcome</span>}
              </div>
              <div className='agenda-stage__block__items__item__text '>
                <p className='agenda-stage__block__items__item__text__title'>Opening words</p>
              </div>
              <div className='agenda-stage__block__items__item__text first'>
                <p className='agenda-stage__block__items__item__text__title'>Lunaticoin</p>
              </div>
            </div>
            
            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>10:40</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/g5iwtqmwKNcsn2CSpXyjbJ?start=2m16s" target={'_blank'}>F*** Central Exchanges - DLCs for the win</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Daniel Karzel</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/g5iwtqmwKNcsn2CSpXyjbJ?start=27m15s" target="_blank" >Code signing & verification</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Michel ‘ketominer’ L</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:20</h3>
                <span className='agenda-stage__block__items__item__header__button'>PANEL</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/g5iwtqmwKNcsn2CSpXyjbJ?start=52m30s" target={'_blank'}>Trading</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Matthew Mezinskis</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Ambroid, Martins Benkitis,<br/>Nik Oraevskiy, Riccardo Masutti</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:40</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/f77FvFUncLHG6LCXdAk5Xi" target={'_blank'}>The Baltic Hansa and Sound Money</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Rahim Taghizadegan</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:55  coffee break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>12:30</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/9FaFi8Xd5Fk9V6W8SPncCr" target={'_blank'}>Cryptography is not enough</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Gigi</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>12:50</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/qYgJd5p2bdxAbpbVmepfG2" target={'_blank'}>Dissecting the soul of smart contracts</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Olga Ukolova, Maksim Orlovsky</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:10</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/qYgJd5p2bdxAbpbVmepfG2?start=21m5s" target={'_blank'}>Lights and shades of Bitcoin as legal tender in El Salvador</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Riccardo Giorgio Frega</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:30</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/qYgJd5p2bdxAbpbVmepfG2?start=43m">Economic Empowerment and Civil Resistance</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Anita Posch</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>14:00  Lunch break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/suXWMyXi1Wko6BgY72jKfR" target={'_blank'}>Watchtowers, all you wanted to know but were too afraid to ask</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Sergi Delgado </p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:20</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/4YUXTy5J5T2mVp2d8rkWw1" target={'_blank'}>Security is hard</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Martin Habovštiak</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:40</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/jfvFLiWUmQwWbmgqnugf7J" target={'_blank'}>Braidpool: Decentralised Mining at Scale</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Kulpreet Singh</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:10</h3>
                <span className='agenda-stage__block__items__item__header__button'>PANEL</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/hkDGtvGG187TxiAqYSyZMx" target={'_blank'}>Bitcoin development</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Aaron Van Wirdum</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Martin Habovstiak, Peter Todd,<br/> Jimmy Song, Lisa Neigut</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:30  Break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:45</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/3Qg8eqzZoxotgBWg3CAXsP" target={'_blank'}>Jailbreaking Bitcoin: The Last Four Years of Mining Firmware  </a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Jan Capek, Pavel Moravec</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>17:10</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/qPDyktDG7JthS2eHqeGrd7">Why wrapped Bitcoin sucks?</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Andriy Velykyy</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>17:35</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/qPDyktDG7JthS2eHqeGrd7?start=28m35s" target={'_blank'}>Recovery seed security and shamir backup</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Josef Tetek</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black last full'>
                <h3 className='agenda-stage__block__items__item__header__text'>18:00  afterparty</h3>
              </div>
            </div>

        </div>
    </div>
    )
}

export default Saturday2nd
