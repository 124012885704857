import React from 'react'
import standardBoothUrl from './../../assets/images/sponsors-icons/standart-booth.svg'
import premiumBoothUrl from './../../assets/images/sponsors-icons/honey-booth.svg'
import soldOutUrl from './../../assets/images//sponsors-icons/sold_out.svg'
import './style.scss'

import "slick-slider/slick/slick.css";
import "slick-slider/slick/slick-theme.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import clsx from 'clsx'


function ExhibitorBooth(props) {
    const boothes = [
        {
            title: 'Standard Booth',
            subtitle: 'Your small booth in the BH exhibit hall',
            img: standardBoothUrl,
            price: '$3 000',
            benefits: 'Benefits',
            color: 'blue',
            sold: '',
            texts: [
                {one: 'Engage the attendees'},
                {two: 'Booth for 2 conference days'},
            ],
        },
        {
            title: 'Honeybadger Booth',
            subtitle: 'A big booth in the exhibit hall with the custom BH setup',
            img: premiumBoothUrl,
            price: '$6 000',
            benefits: 'Benefits',
            color: 'red',
            sold: '',
            texts: [
                {one: 'Engage the attendees'},
                {two: 'Booth for 2 conference days'},
                {three: 'Branded hard wall backdrops, flat TV, and two chairs'},
                {four: 'Prepared for you'},
            ],
        },
    ]

    const boothesSlides = () => boothes.map(booth => (
        <div className={clsx("inner-card", booth?.sold)}>
            <div className="sold_out">
                <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
                <div className={clsx("inner-card__header", booth.color)}>
                    <h3 className="card__title">{booth.title}</h3>
                    <img src={booth.img} alt="" />
                </div>
                <div className="card__body">
                    <h3 className="card__money">{booth.price}</h3>
                    <p className="card__description">{booth.subtitle}</p>
                    <p className="card__list-title">{booth.benefits}</p>
                    <ul className="card__list">
                        {booth.texts?.map(text => (
                            <li key={text.one} className="card__elem">{text?.one || text?.two || text?.three || text?.four }</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
     ))

    const screenWidth = window.screen.width;
    let small = screenWidth < 767;

  return (
    <div className="sponsors-block" {...props}>
        <h2 className="sponsors-block__title">Exhibitor Booths</h2>
        <p className="sponsors-block__subtitle">Your booth in our exhibit hall</p>

        {
            small ? (
                    <Swiper
                        navigation={false}
                        pagination={{ clickable: true }}
                        modules={[Pagination, A11y]}
                        slidesPerView={1}
                        spaceBetween={30}
                        grabCursor={true}
                        className="mySwiper"
                    >
                        {boothes.map(booth => (
                            <SwiperSlide>
                                <div className={clsx("inner-card", booth?.sold)}>
                                <div className="sold_out">
                                    <img src={soldOutUrl} alt="" />
                                </div>
                                    <div className="element">
                                        <div className={clsx("inner-card__header", booth.color)}>
                                            <h3 className="card__title">{booth.title}</h3>
                                            <img src={booth.img} alt="" />
                                        </div>
                                        <div className="card__body">
                                            <h3 className="card__money">{booth.price}</h3>
                                            <p className="card__description">{booth.subtitle}</p>
                                            <p className="card__list-title">{booth.benefits}</p>
                                            <ul className="card__list">
                                                {booth.texts?.map(text => (
                                                    <li key={text.one} className="card__elem">{text?.one || text?.two || text?.three || text?.four }</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
            ) : (
                <div className="inner-cards">
                    {boothesSlides()}
                </div>
            )
        }

    </div>
  )
}

export default ExhibitorBooth
