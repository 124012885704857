import { useState, useCallback, useEffect } from 'react'

import loadConfig from '../utils/loadConfig'

const useTicketBuy = () => {
    const [links, setTicketLinks] = useState({})

    const onBuyTicketBtc = useCallback(function (e) {
        e?.preventDefault();

        window.open(links.btc, '_blank');
    }, [links])

    const onBuyTicketFiat = useCallback(function (e) {
        e?.preventDefault();

        window.open(links.fiat, '_blank');
    }, [links])

    useEffect(() => {
        async function loadLinks() {
            const config = await loadConfig()

            setTicketLinks({
                btc: config.btcBuyUrl,
                fiat: config.fiatBuyUrl
            })
        }

        loadLinks()
    }, [])

    return [onBuyTicketBtc, onBuyTicketFiat]
}

export default useTicketBuy
