import React from 'react'
import { Link } from 'react-router-dom';

import './conferenceTickets.scss'

import useTicketBuy from '../../hooks/useTicketBuy'

import digitalBrandingUrl from './../../assets/images/sponsors-icons/digital-ticket-branding.svg'

function ConferenceTickets() {
    const screenWidth = window.screen.width;
    const desktop = screenWidth > 1439;
    const smallDesktop = screenWidth > 1023;

    const [onBuyTicketBtc, onBuyTicketFiat] = useTicketBuy();

  return (
        <section className="conference-tickets">
            <div className="conference-tickets__container">
                {
                    desktop ? (
                        <h1 className="conference-tickets__title">2-day BH2022 conference ticket</h1>
                    ) : (
                        <h1 className="conference-tickets__title">2-day BH2022<br/> conference ticket</h1>
                    )
                }

                {
                    smallDesktop ? (
                        <p className="conference-tickets__subtitle">A major annual Bitcoin event in both Baltics and the world,<br/> dedicated to Bitcoin and technologies built around it</p>
                    ) : (
                        <p className="conference-tickets__subtitle">A major annual Bitcoin event in both Baltics and the world, dedicated to<br/> Bitcoin and technologies built around it</p>
                    )
                }

                <div className="conference-tickets__card">
                    <div className="card">
                        <div className="card__header">
                            <div className="card__about-price">
                                <span className="card__price">€302.<span className="card__price__tail">50</span>*</span>
                                /Full Pass
                            </div>
                            <img src={digitalBrandingUrl} alt="digital branding" width="102" height="102" className="card__img"/>
                        </div>
                        <div className="card__body">
                            <ul className="card__list">
                                <li className="card__elem">Full access to all conference facilities</li>
                                <li className="card__elem"><span>By purchasing the ticket, you accept our <Link className='card__elem__link' to="/terms-conditions">T&C</Link></span></li>
                            </ul>
                            <div className="card__buttons">
                                <button onClick={onBuyTicketBtc} className="button button--primary">BUY with Bitcoin</button>
                                <button onClick={onBuyTicketFiat} className="button">Buy with fiat</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="conference-tickets__disclaimer">*Ticket price to be increased soon</div>
            </div>
            <div className="conference-tickets__honeybadger"/>
        </section>
  )
}

export default ConferenceTickets
