import React from 'react'
import SpeakersList from './../../components/SpeakersList/SpeakersList'
import Banner from './../../components/Banner/Banner'

import './speakers.scss'

function Speakers() {
    const screenWidth = window.screen.width;
    let desktop = screenWidth > 1439;

    // const [windowWidth, setWindowWidth] = useState(0);
    // const [windowHeight, setWindowHeight] = useState(0);
    //
    // let resizeWindow = () => {
    //   setWindowWidth(window.innerWidth);
    //   setWindowHeight(window.innerHeight);
    // };
    //
    // useEffect(() => {
    //   resizeWindow();
    //   window.addEventListener("resize", resizeWindow);
    //   return () => window.removeEventListener("resize", resizeWindow);
    // }, []);

    return (
        <div className="page speakers">
            {
                desktop ? (
                    <Banner>
                        <span>Cypherpunk movement for <span className="comic-text comic-text--banner">passionate Bitcoiners</span></span>
                    </Banner>
                ) : (
                    <Banner>
                        <span>Cypherpunk movement for</span> <br/><span className="comic-text comic-text--banner">passionate Bitcoiners</span>
                    </Banner>
                )

            }
            <SpeakersList/>
        </div>
    )
}

export default Speakers
