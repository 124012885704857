import React from 'react'
import liveStreamUrl from './../../assets/images/sponsors-icons/lifestream.svg'
import wifiUrl from './../../assets/images/sponsors-icons/wifi.svg'
import chillZoneUrl from './../../assets/images/sponsors-icons/chill-zone.svg'
import chargingPointUrl from './../../assets/images/sponsors-icons/charging-point.svg'
import micStageUrl from './../../assets/images/sponsors-icons/mic-stage.svg'
import loungeZoneUrl from './../../assets/images/sponsors-icons/lounge-zone.svg'
import soldOutUrl from './../../assets/images//sponsors-icons/sold_out.svg'
import exclusiveSmallUrl from './../../assets/images/sponsors-icons/exclusive-line.svg'
import './style.scss'

import clsx from 'clsx'

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/pagination";


function ConferenceFacilities(props) {
    const facilities = [
        {
            title: 'Charging point',
            img: chargingPointUrl,
            price: '$3 000',
            benefits: 'Benefits',
            color: 'blue',
            sold: '',
            texts:
            [
                {one: 'On-site Branding'},
                {two: 'Your custom design on all charging points'}
            ],
            exclusiveUrl: exclusiveSmallUrl,
        },
        {
            title: 'Open mic stage branding',
            img: micStageUrl,
            price: '$7 500',
            benefits: 'Benefits',
            color: 'black',
            sold: '',
            texts: [
                {one: 'The second stage of the conference featured under your brand name'},
            ],
            exclusiveUrl: exclusiveSmallUrl,
        },
        {
            title: 'Wifi sponsor',
            img: wifiUrl,
            price: '$5 000',
            benefits: 'Benefits',
            color: 'red',
            sold: '',
            texts: [
                {one: 'Customised WiFi name'},
                {two: 'Your ad on the wifi setup page'},
                {three: 'Redirection on the landing page of your choice'},
            ],
            exclusiveUrl: exclusiveSmallUrl,
        },
        {
            title: 'Chill zone branding',
            img: chillZoneUrl,
            price: '$7 000',
            benefits: 'Benefits',
            color: 'black',
            sold: '',
            texts: [
                {one: 'One of the main conference facilities featured under your brand name'},
            ],
            exclusiveUrl: exclusiveSmallUrl,
        },
        {
            title: 'Press room setup',
            img: liveStreamUrl,
            price: '$2 500',
            benefits: 'Benefits',
            color: 'blue',
            sold: '',
            texts: [
                {one: 'Your own branded press room'},
                {two: '2 premium press passes'},
            ],
            exclusiveUrl: exclusiveSmallUrl,
        },
        {
            title: 'Your BRANDED lounge',
            img: loungeZoneUrl,
            price: '$7 000',
            benefits: 'Benefits',
            color: 'black',
            sold: 'sold',
            texts: [
                {one: 'Host your own lounge during the conference'},
                {two: 'Your custom branding '},
            ],
            exclusiveUrl: exclusiveSmallUrl,
        },
        {
            title: 'Coffee station sponsor',
            img: chillZoneUrl,
            price: '$5 000',
            benefits: 'Benefits',
            color: 'red',
            sold: 'sold',
            texts: [
                {one: 'On-site Branding'},
                {two: 'Customised coffee cups'}
            ],
            exclusiveUrl: exclusiveSmallUrl,
        },
    ]

    const facilitiesSlides = () => facilities.map(facility => (
        <div className={clsx("inner-card", facility?.sold)} key={facility?.title}>
            <div className="sold_out">
                <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
                <div className={clsx(facility?.color, "inner-card__header")}>
                    <h3 className="card__title">{facility?.title}</h3>
                    <img src={facility?.img} alt="" />
                </div>
                <img src={facility?.exclusiveUrl} alt="" className="exclusive-border"/>
                <div className="card__body">
                <h3 className="card__money">{facility?.price}</h3>
                    <p className="card__list-title">{facility?.benefits}</p>
                    <ul className="card__list">
                        {facility?.texts?.map(text => (
                            <li className="card__elem">{text?.one || text?.two || text?.three }</li>
                        ))}
                     </ul>
                 </div>
             </div>
         </div>
     ))

    const screenWidth = window.screen.width;
    let small = screenWidth < 767;

  return (
    <div className="sponsors-block" {...props}>
        <h2 className="sponsors-block__title">Conference Facilities</h2>
        <p className="sponsors-block__subtitle">Become the exclusive sponsor of BH conference facilities</p>

        {
            small ? (
                <Swiper
                    navigation={false}
                    pagination={{ clickable: true }}
                    modules={[Pagination, A11y]}
                    slidesPerView={1}
                    spaceBetween={30}
                    grabCursor={true}
                    className="mySwiper"
                    >
                    {facilities.map(facility => (
                        <SwiperSlide>
                            <div className={clsx("inner-card", facility?.sold)} key={facility?.title}>
                                <div className="element">
                                    <div className="sold_out">
                                        <img src={soldOutUrl} alt="" />
                                    </div>
                                    <div className={clsx("inner-card__header", facility.color)}>
                                        <h3 className="card__title">{facility.title}</h3>
                                        <img src={facility.img} alt="" />
                                    </div>
                                    <img src={facility?.exclusiveUrl} alt="" className="exclusive-border"/>
                                    <div className="card__body">
                                        <h3 className="card__money">{facility.price}</h3>
                                        <p className="card__list-title">{facility.benefits}</p>
                                        <ul className="card__list">
                                            {facility.texts?.map(text => (
                                                <li key={text.one} className="card__elem">{text?.one || text?.two || text?.three || text?.four }</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
            </Swiper>
            ) : (
                <div className="inner-cards rows-4">
                    {facilitiesSlides()}
                </div>
            )
        }

    </div>
  )
}

export default ConferenceFacilities

