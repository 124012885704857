import React, { useEffect } from 'react'
import './style.scss'

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import lightUrl from './../../assets/images/light.png'

function LightPromo( {text} ) {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo("#eventbannerjs", {
        autoAlpha: 0,
        scale: .6
      },
      {
        duration: .6,
        autoAlpha: 1,
        scale: 1,
        scrollTrigger: {
            trigger: ".subscribe",
            start: "center, +=500",
            end: "+=200",
            toggleActions: 'play none none reverse'
        }
      }
    );
}, []);

  return (
    <div className="light" id="eventbannerjs">
        <img src={lightUrl} alt="event banner" className="light__img" />
        <p className="light__text">{text}</p>
    </div>
  )
}

export default LightPromo
