import React from 'react'
import exhibitorss from '../../exhibitors.json';
import './../Partners/style.scss'

const IMAGE_URI = '/images/exhibitors/';

const  Exhibitors = () => {

    let ExhibitorsItem = exhibitorss.map(exhibItem => {
        const url = IMAGE_URI + exhibItem.image;

        return (
            <div key={exhibItem.id} id={exhibItem.id}>
                <a href={exhibItem.url} target="_blank" key={exhibItem.id} id={exhibItem.id} className="partners__logo" rel="noreferrer">
                    <img src={url} alt={exhibItem.image} className="partner__image"/>
                </a>
            </div>
        )
    })
  return (
    <div className="partners__logos">
        {ExhibitorsItem}
    </div>
  )
}

export default Exhibitors
