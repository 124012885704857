import React from 'react'
import Button from '../../components/Button/Button'
import btspay from './../../assets/images/btspay-week.png'


import './style.scss'

const FifthDay = (props) => {
  const screenWidth = window.screen.width;
  let small = screenWidth < 768;
  
  return (
     <div className='days-blocks' {...props}>
        <div className='days-blocks__header'>
          {small ? <h1 className='days-blocks__header__title'>BTCPayServer day,<br/> September 5th</h1> : <h1 className='days-blocks__header__title'>BTCPayServer day, September 5th</h1>}
          {small ? <p className='days-blocks__header__subtitle'>Talks, demos, workshops, and user <br/> experiences presented by the BTCPay Server team</p> : <p className='days-blocks__header__subtitle'>Talks, demos, workshops, and user experiences presented by the BTCPay Server team</p>}
        </div>
        <div className='days-blocks__block'>
          <div className='days-blocks__block__head green'>
            {small ? <h2 className='days-blocks__block__head__title'>Bitcoin  Payment Processing</h2> : <h2 className='days-blocks__block__head__title small-title'>Bitcoin  Payment <br/>Processing</h2>}
            <div className='days-blocks__block__head__subtitle_block no-margin'>
              <p className='days-blocks__block__head__subtitle_block__item white clock'>14.00 - 19.00</p>
              <p className='days-blocks__block__head__subtitle_block__item white ticket'>Invite only</p>
              <p className='days-blocks__block__head__subtitle_block__item white location'>OraculeTang Space, Doma laukums 2</p>
            </div>
            {/* {!small && <button className='button '><a rel="noreferrer" target='_blank' href='https://day.btcpayserver.org/'>Buy Tickets</a></button>} */}
            {/* {!small &&  <button className='button button__black button__second'><a rel="noreferrer" target='_blank' href='https://goo.gl/maps/rsy93ZSrGURWKo6G9'>GET Directions</a></button>} */}
          </div>
          {small ? 
          (
            <div className='days-blocks__block__info'>
              <a href='https://btcpayserver.org/' target='_blank' rel="noreferrer"><img className='days-blocks__block__info__content-img' src={btspay} alt="BTC-pay" /></a>
              <p className='days-blocks__block__info__title'>Hosted by the BTCPay Server team </p>
              <p className='days-blocks__block__info__content'>A self-hosted, open-source cryptocurrency payment <br/>processor sharing its experiences and developments. </p>
              <div className='days-blocks__block__buttons'> 
                {/* <button className='button'><a rel="noreferrer" target='_blank' href='https://day.btcpayserver.org/'>Buy Tickets</a></button> */}
                <button className='button button__black'><a rel="noreferrer" target='_blank' href='https://goo.gl/maps/rsy93ZSrGURWKo6G9'>GET Directions</a></button>
              </div>
            </div>
          )
           : (
            <div className='days-blocks__block__info'>
              <p className='days-blocks__block__info__title'>Hosted by the BTCPay Server team </p>
              <p className='days-blocks__block__info__content'>A self-hosted, open-source cryptocurrency payment <br/>processor sharing its experiences and developments. </p>
              <a href='https://btcpayserver.org/' target='_blank' rel="noreferrer"><img className='days-blocks__block__info__content-img' src={btspay} alt="BTC-pay" /></a>
            </div>
           ) }
        </div>
    </div>
  )
}

export default FifthDay