import React from 'react'
import september1 from './../../assets/images/september1.png'
import september1sm from './../../assets/images/september1sm.png'

import './style.scss'

const FirstDay = (props) => {
  const screenWidth = window.screen.width;
  let small = screenWidth < 768;

  return (
    <div className='days-blocks' {...props}>
        <div className='days-blocks__header'>
          {small ? <h1 className='days-blocks__header__title'>NOOB day,<br/> September 1st</h1> : <h1 className='days-blocks__header__title'>NOOB day, September 1st</h1>}
          {small ? <p className='days-blocks__header__subtitle'>Introduction to Bitcoin<br/> and non-techical talks</p> : <p className='days-blocks__header__subtitle'>Introduction to Bitcoin and non-techical talks</p>}
        </div>
        <div className='days-blocks__block'>
          <div className='days-blocks__block__head blue'>
            {small ? <h2 className='days-blocks__block__head__title'>Welcome to Bitcoin</h2> : <h2 className='days-blocks__block__head__title'>Welcome <br /> to Bitcoin</h2>}
            <div className='days-blocks__block__head__subtitle_block'>
              <p className='days-blocks__block__head__subtitle_block__item clock'>12:00 - 18:00</p>
              <p className='days-blocks__block__head__subtitle_block__item ticket'>Register for free</p>
              <p className='days-blocks__block__head__subtitle_block__item location'>OraculeTang Space,<br/> <span> Doma laukums 2</span></p>
            </div>
            {/* {!small && <button className='button button__red'><a target='_blank' rel="noreferrer" href='https://www.eventbrite.com/e/riga-bitcoin-week-noob-day-welcome-to-bitcoin-tickets-360384207867'>Register</a></button>} */}
            {/* {!small && <button className='button button__black button__second'><a target='_blank' rel="noreferrer" href='https://goo.gl/maps/rsy93ZSrGURWKo6G9'>GET Directions</a></button>} */}
            {small ? <img className='bottom-illustration' src={september1sm} alt='BagBtc' /> : <img className='bottom-illustration' src={september1} alt='BagBtc' />}
          </div>
          <div className='days-blocks__block__info'>
            <p className='days-blocks__block__info__title'>Speakers</p>
            <p className='days-blocks__block__info__content-big'>
              <a href='https://twitter.com/BTCSchellingPt' target='_blank' rel="noreferrer">WizardofAus,</a> 	
              <a href='https://twitter.com/proofofsteph' rel="noreferrer" target='_blank'>Steph,</a> 	
              <a href='https://twitter.com/knutsvanholm' rel="noreferrer" target='_blank'>Knut Svanholm,</a>  {small && <br/>}
              <a href='https://twitter.com/dergigi' target='_blank' rel="noreferrer">Gigi,</a>   
              <a href='https://twitter.com/AnitaPosch' target='_blank' rel="noreferrer">Anita Posch,</a> 
              <a href='https://twitter.com/BitcoinQ_A' target='_blank' rel="noreferrer">Bitcoin Q+A,</a>   {!small && <br/>}
              <a href='https://twitter.com/giacomozucco?s=21&t=Rn3liACaEzU1odq6JreXVA' target='_blank' rel="noreferrer">Giacomo Zucco</a> 
              </p>
            <p className='days-blocks__block__info__title topic'>Topics</p>
            <div className='days-blocks__block__info__topics'>
              <p className='days-blocks__block__info__content-big no-padding listItem margin-md '>Opening discussion - What the heck is Bitcoin?</p>
              <p className='days-blocks__block__info__content-big no-padding listItem margin-md '>Why is money broken, and how does Bitcoin fix this?</p>
              <p className='days-blocks__block__info__content-big no-padding listItem margin-md '>The paradoxes of Bitcoin</p>
              <p className='days-blocks__block__info__content-big no-padding listItem margin-md '>Bitcoin as a tool of financial empowerment</p>
              <p className='days-blocks__block__info__content-big no-padding listItem margin-md '>Practical bitcoin security advice</p>
              <p className='days-blocks__block__info__content-big no-padding listItem margin-md '>The natural, necessary, and synergistic tension between NgU and Privacy</p>
            </div>
            {small && <div className='days-blocks__block__buttons'> 
              <button className='button button__red'><a target='_blank' rel="noreferrer" href='https://www.eventbrite.com/e/riga-bitcoin-week-noob-day-welcome-to-bitcoin-tickets-360384207867'>Register</a></button> 
              {/* <button className='button button__black button__second'><a target='_blank' rel="noreferrer" href='https://goo.gl/maps/skQhdUibTWXZVhVF8'>GET Directions</a></button> */}
            </div>}
          </div>
        </div>
    </div>
  )
}

export default FirstDay