import React from 'react'

import '../style.scss'

function Sunday() {
    const screenWidth = window.screen.width;
    let small = screenWidth < 768;

    return (
    <div className='agenda-stage'>
         <div className='agenda-stage__header'>
          <h1 className='agenda-stage__header__title'>Sunday, September 4th</h1>
          <p className='agenda-stage__header__subtitle'>MC Stephan Livera</p>
        </div>
        <div className='agenda-stage__block'>
            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green first'>
                <h3 className='agenda-stage__block__items__item__header__text'>10:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text '>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/hGWeHH6rPzEBfoiH24j6SP?start=2m" target={'_blank'}>Bitcoin Psychopaths vs Bitcoin Sociopaths</a>
                </p>
              </div>
              <div className={small ? 'agenda-stage__block__items__item__text' : 'agenda-stage__block__items__item__text first'}>
                <p className='agenda-stage__block__items__item__text__title'>Rigel Walshe</p>
              </div>
            </div>
            
            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>10:25</h3>
                <span className='agenda-stage__block__items__item__header__button'>PANEL</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/hGWeHH6rPzEBfoiH24j6SP?start=24m15s" target={'_blank'}>Security</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Daniel Prince</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Peter Todd, Pavol Rusnak,<br/> Douglas Bakkum, Rigel Walshe</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/hGWeHH6rPzEBfoiH24j6SP?start=59m30s" target={'_blank'}>Presentation</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Mikhail Chobanyan</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:25</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/hGWeHH6rPzEBfoiH24j6SP?start=1h19m" target={'_blank'}>Bringing Bitcoin to the World through Play</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Desiree Dickerson</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:45</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/hGWeHH6rPzEBfoiH24j6SP?start=1h38m" target={'_blank'}>Stuff learned building a wallet</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Igor Korsakov</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>12:15  coffee break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>12:35</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/aVPHcHjmBxbsJaU1KfsUWz" target={'_blank'}>Casino Games</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Dan Held</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>PANEL</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/aVPHcHjmBxbsJaU1KfsUWz?start=24m" target={'_blank'}>Economy</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Marty Bent</p>

              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Max Kei, Nicolas Dorier,<br/> Samson Mow, Desiree Dickerson</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:30</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/aVPHcHjmBxbsJaU1KfsUWz?start=1h2m30s" target={'_blank'}>Bitcoin and Islamic Finance</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Allen Farington</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:50</h3>
                <span className='agenda-stage__block__items__item__header__button'>
                 <a href="https://bitcointv.com/w/aVPHcHjmBxbsJaU1KfsUWz?start=1h24m15s" target={'_blank'}>QnA</a>
                </span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>QnA</p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Daniel Prince</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Abubakar Nur Khalil</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black '>
                <h3 className='agenda-stage__block__items__item__header__text'>14:10</h3>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/aVPHcHjmBxbsJaU1KfsUWz?start=1h44m5s" target={'_blank'}>Announcements</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Trezor, Hodl Hodl</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>14:20  Lunch break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:10</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/k433bJMhrr37yhp1Az8h7V?start=2m32s" target={'_blank'}>Lightning: You're Doing Everything Wrong</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Warren Togami</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:40</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/k433bJMhrr37yhp1Az8h7V?start=35m" target={'_blank'}>Evolution of the mempool open source project</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>wiz</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/k433bJMhrr37yhp1Az8h7V?start=46m30s" target={'_blank'}>Proof of Assets: Armining Bitcoiners</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Sam Abbassi</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:20  Break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:35</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/8gKugd878URQyu3ykKgLDW" target={'_blank'}>The Rootstock Bitcoin Sidechain</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Sergio Lerner</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:50</h3>
                <span className='agenda-stage__block__items__item__header__button'>Panel</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/8gKugd878URQyu3ykKgLDW?start=20m37s" target={'_blank'}>Unpopular opinion</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Marty Bent</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Giacomo Zucco, Peter Todd,<br/> Paul Sztorc, Eric Voskuil</p>
              </div>
            </div>

            {/* <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>17:20</h3>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>RHR Live Show Day 2</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Marty Bent</p>
              </div>
            </div> */}

            <div className='agenda-stage__block__items'>
            <div className={small ? 'agenda-stage__block__items__item__header blue' : 'agenda-stage__block__items__item__header blue last'}>
                <h3 className='agenda-stage__block__items__item__header__text'>18:00</h3>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Closing words</p>
              </div>
              <div className='agenda-stage__block__items__item__text last'>
                <p className='agenda-stage__block__items__item__text__title'>Max Kei</p>
              </div>
            </div>

        </div>
    </div>
    )
}

export default Sunday
