import React from 'react'
import picUrlCut from './../../assets/images/biggest-bitcoin-conference-cut.jpg'
import picUrlSmall from './../../assets/images/biggest-bitcoin-conference-small.jpg'
import picUrlCutWebp from './../../assets/images/biggest-bitcoin-conference.webp'

import './style.scss'

function AgendaList() {
    return (
        <section className="agenda-list">
            <picture>
                <source srcSet={picUrlSmall} type="image/jpg" media="(max-width: 767px)" sizes="(max-width: 767px)"/>
                <source srcSet={picUrlCutWebp} type="image/webp" />
                <img src={picUrlCut} alt="Biggest Bitcoin Conference" width="531" className="agenda-list__pic" />
            </picture>
            <div className="agenda-list__plan">
                <div className="agenda-list__item">
                    <h2 className="block__title">Riga Bitcoin Week 2022</h2>
                    <p className="block__text">Riga Bitcoin Week is a festival dedicated to Bitcoin development and adoption and will consist of various entertainment, educational, and networking events.</p>
                </div>
                <div className="agenda-list__item">
                    <h2 className="block__title">BH2022 - Day 1</h2>
                    <p className="block__text">The first day of the conference will cover presentations and panels about the most critical topics for Bitcoin’s development, host an open microphone stage where the new ideas will be born and presented and will end with an afterparty where all attendees will have a chance to meet our speakers in an informal atmosphere.</p>
                </div>
                <div className="agenda-list__item">
                    <h2 className="block__title">BH2022 - Day 2</h2>
                    <p className="block__text">The second day of the conference will become a culmination of Riga Bitcoin Week 2022, which will highlight the essential intakes and ideas, hosting some of your favorite speakers during panels and fireside chats.</p>
                </div>

                {/* <Link to="/agenda" className="agenda-list__wrapper" alt="Agenda">
                    <p className="agenda-list__link">Schedule</p>
                    <img src={arrowUpUrl} alt="Speakers" className="agenda-list__image"/>
                </Link> */}
            </div>
        </section>
    )
}

export default AgendaList
