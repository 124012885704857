import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import logoUrl from './../../assets/images/logo.svg'
import closeMenuUrl from './../../assets/images/close.svg'
import gsap from 'gsap'

import Button from './../Button/Button'
import './style.scss'


function Navigation() {


    const [menu, setMenu] = useState(false);
    const showMenu = () => {
        if (!menu){
            gsap.to("#menu", {duration: .1, left: 0})
            setMenu(!menu)
        } else if (menu){
            gsap.to("#menu", {duration: .1, left: "-100%"})
            setMenu(!menu)
        }
    }

    return (
        <Fragment>
            <div className="nav">
                <div className="nav__container page-container">
                    <div className="nav__left">
                        <Link to="/" alt="Main page">
                            <img src={logoUrl} alt="Logo" className="nav__logo" />
                        </Link>
                        <div className="nav__date">September 3 - 4, RIGA</div>
                    </div>

                    <div className="header__menu menu nav__right">
                        <div onClick={() => showMenu()} className="menu__icon">
                            <span />
                        </div>

                        <nav onClick={() => showMenu()} id="menu" className="menu__body">
                            <img src={closeMenuUrl} alt="close menu button" className="menu__close" />
                            <ul className="menu__list">
                                <Link onClick={() => showMenu()} to="/agenda" className="nav__link nav__link_button" alt="Agenda">Agenda</Link>
                                <Link onClick={() => showMenu()} to="/speakers" className="nav__link nav__link_button" alt="Speakers">Speakers</Link>
                                <Link onClick={() => showMenu()} to="/riga-bitcoin-week" className="nav__link nav__link_button" alt="RigaBitcoinWeek">Riga Bitcoin Week</Link>
                                {/* <Button title="REGISTER" /> */}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Navigation


