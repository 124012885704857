import React from 'react'
import MultiToggle from '../../components/MultiToggle/MultiToggle'
import FifthDay from './FifthDay'
import FirstDay from './FirstDay'
import SecondDay from './SecondDay'
import ThirdDay from './ThirdDay'

import './style.scss'

function BitcoinWeek() {
    const screenWidth = window.screen.width;
    let small = screenWidth < 768;

    return (
        <div className="page bitcoin-week">
            <div className="bitcoin-week__header">
                {
                    small ? <h1 className="bitcoin-week__title">Riga <br/> Bitcoin Week 2022</h1> : <h1 className="bitcoin-week__title">Riga Bitcoin Week 2022</h1>
                }
                <p className="bitcoin-week__subtitle">A week of high-quality educational content delivered by the top industry players</p>
            </div>
            <div className="bitcoin-week__container">
                <MultiToggle
                    title='Select Day'
                    items={[
                        { title: 'September 1st', id: 'september1st' },
                        { title: 'September 2nd', id: 'september2nd' },
                        { title: 'September 3rd & 4th ', id: 'september3rd4th' },
                        { title: 'September 5th', id: 'september5th' },
                    ]}
                />

                <FirstDay id='september1st'/>

                <SecondDay id='september2nd' />

                <ThirdDay id='september3rd4th' />

                <FifthDay id='september5th' />
            </div>
            <div className="bitcoin-week__footer">
                <div className="bitcoin-week__honeybadger"/>
            </div>
        </div>
    )
}

export default BitcoinWeek
