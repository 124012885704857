import React from 'react'

import Button from '../Button/Button'
import './header.scss';

import Lottie from 'lottie-react';
import animation from './HB_lasers.json';
import animationSmall from './HB_lasers_mobile.json';

const SMALL_LIMIT = 767;
const screenWidth = window.screen.width;
let small = screenWidth < SMALL_LIMIT;

function Header({ className, subtitle, children, title }) {

    return (
        <div className={`header ${className}`}>
            <h3 className="header__subtitle">{subtitle}</h3>
            <div className="header__wrap">
                <h1 className="box header__title" title={title}>
                    {title}
                </h1>
            </div>

            <div className="header__badger">
                <Lottie animationData={small ? animationSmall : animation} loop={true} autoPlay={true} />
            </div>

            {/* <Button title="REGISTER"/> */}

            <div className="header__text">
                {children}
            </div>
        </div>
    )
}

export default Header
