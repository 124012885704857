import React, { useEffect } from 'react'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Header from '../components/Header/Header';
import VideoSlider from '../components/VideoSlider/VideoSlider'
import Banner from '../components/Banner/Banner'
import Plan from '../components/Plan/Plan'
import ComplexBanner from '../components/ComplexBanner/ComplexBanner'
import SpeakersList from '../components/SpeakersList/SpeakersList'
import AgendaList from '../components/AgendaList/AgendaList'
import PromoVideo from '../components/PromoVideo/PromoVideo';
import Partners from '../components/Partners/Partners';
import ArticleSlider from '../components/ArticleSlider/ArticleSlider';
import SignInForConference from '../components/SignInForConference/SignInForConference';
import BlueBanner from '../components/BlueBanner/BlueBanner';
import LightPromo from '../components/LightPromo/LightPromo';

function Main() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo("#bluebannerjs", {
                autoAlpha: 0,
                scale: .6
              },
              {
                duration: .6,
                autoAlpha: 1,
                scale: 1,
                scrollTrigger: {
                    trigger: "#complex-banner",
                    start: "top, +=500",
                    end: "+=200",
                    toggleActions: 'play none none reverse'
                }
              }
        );
    }, []);

    const screenWidth = window.screen.width;
    let desktop = screenWidth > 1439;
    // let smallDesktop = screenWidth > 1023;

    // const [windowWidth, setWindowWidth] = useState(0);
    // const [windowHeight, setWindowHeight] = useState(0);

    // let resizeWindow = () => {
    //   setWindowWidth(window.innerWidth);
    //   setWindowHeight(window.innerHeight);
    // };

    // useEffect(() => {
    //   resizeWindow();
    //   window.addEventListener("resize", resizeWindow);
    //   return () => window.removeEventListener("resize", resizeWindow);
    // }, []);

    const headerBanner = desktop ? (
        <Banner>
            <span className="wrap">Providing <span className="comic-text comic-text--header">high-quality content</span></span>
            <span className="inner">and networking opportunities</span>
        </Banner>
    ) : (
        <Banner>
            <span className="inner">Providing</span> <span className="comic-text comic-text--header">high-quality content</span>
            <span className="inner">and networking opportunities</span>
        </Banner>
    )

    const banner = desktop ? (
        <Banner>
            <span>Cypherpunk movement for <span className="comic-text comic-text--banner">passionate Bitcoiners</span></span>
        </Banner>
    ) : (
        <Banner>
            <span>Cypherpunk movement for</span> <br/><span
            className="comic-text comic-text--banner">passionate Bitcoiners</span>
        </Banner>
    );

    return (
        <div className="page">
            <Header
                className="page-container"
                title="Baltic HoneyBadger 2022"
                subtitle="Biggest European Bitcoin-only conference"
                alt="Baltic HoneyBadger 2022"
            >
                {headerBanner}
            </Header>
            <main>
                <section className="page-container">
                    <VideoSlider/>
                    {banner}
                    <Plan/>
                    <section className="high-speakers">
                        <ComplexBanner/>
                        <SpeakersList/>
                    </section>

                    <BlueBanner/>
                    <AgendaList/>
                </section>

                <section>
                    <PromoVideo />
                    {/* <SignInForConference title="SUBSCRIBE FOR CONFERENCE NEWS"/> */}
                </section>

                <section className="page-container">

                    <LightPromo
                        text='Not promoting various so called altcoins, tokens and other blockchain-based "snake oil"'/>
                    <Partners/>
                    <ArticleSlider/>
                </section>
            </main>
        </div>
    )
}

export default Main
