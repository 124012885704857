import React from 'react'

import '../style.scss'

function Sunday2nd() {
    const screenWidth = window.screen.width;
    let small = screenWidth < 768;

    return (
    <div className='agenda-stage'>
         <div className='agenda-stage__header'>
          <h1 className='agenda-stage__header__title'>Sunday, September 4th</h1>
          <p className='agenda-stage__header__subtitle'>MC Lunaticoin</p>
        </div>
        <div className='agenda-stage__block'>
            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green first'>
                <h3 className='agenda-stage__block__items__item__header__text'>10:30</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text '>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/rSbTVn5X6wtnDaQYFMY5bm" target={'_blank'}>Building a (less evil) bank</a>
                </p>
              </div>
              <div className={small ? 'agenda-stage__block__items__item__text' : 'agenda-stage__block__items__item__text first'}>
                <p className='agenda-stage__block__items__item__text__title'>Ben Price</p>
              </div>
            </div>
            
            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>10:50</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
              <p className='agenda-stage__block__items__item__text__title'>
                <a href="https://bitcointv.com/w/rSbTVn5X6wtnDaQYFMY5bm" target={'_blank'}>To Communist or UnCommunist</a>
              </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Aleks Svetski</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:10</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/rSbTVn5X6wtnDaQYFMY5bm" target={'_blank'}>Deploying Specter Desktop - Mechanism, not Policy</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Kim </p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:30</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/tzNZxXQftq7PjsZE2r2zFD" target={'_blank'}>Hardware wallets: Mind the (air) gap!</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Douglas Bakkum</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>11:50</h3>
                <span className='agenda-stage__block__items__item__header__button'>PANEL</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/stic3WDkA8pJpDMyQmLs5E" target={'_blank'}>Emerging markets</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Vlad Costea</p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Obi Nwosu, Daniel Alos, <br/>Anita Posch, Prince Filip</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>12:20  coffee break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>12:35</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/jXXQvTw7QSz1webJe53g13" target={'_blank'}>Bip300: Getting to 100% Bitcoin Dominance (and Beyond)</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Paul Sztorc</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:00</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/11NHN35rDR2oVN6p1hcxDR" target={'_blank'}>What’s this Tail Emission Thing?</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Eric Voskuil</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header blue'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:30</h3>
                <span className='agenda-stage__block__items__item__header__button'>PANEL</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/4PPBY64XYKCbE7ZPzDMszd" target={'_blank'}>Smart contracts</a>
                </p>
                <p className='agenda-stage__block__items__item__text__text'>Hosted by Aaron Van Wirdum</p>

              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Daniel Karzel, Maksim Orlovsky,<br/> Eric Sirion, Marco Argentieri</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>13:50</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/oD13cuhqnDRdAtFoVAVsRp" target={'_blank'}>NBXplorer</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Nicolas Dorier</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>14:20  Lunch break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:10</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/rfbV96fCC5yezwa1ipzrkD" target={'_blank'}>Fungibility</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Max Hillebrand</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:30</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/rfbV96fCC5yezwa1ipzrkD" target={'_blank'}>Fungibility</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Adam Ficsor, Pavol Rusnak
                </p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>15:50</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/p5g3W7w4GosFdNb9WMrGsz" target={'_blank'}>Federated E-Cash and beyond</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Eric Sirion</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header red full'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:20  Break</h3>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:35</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/a2nsBffzdZueb4dS3Fm9bg">Bitcoin's killer app is YOU!</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Knut Svanholm</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>16:55</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/jKSA7oJj27zF5NQnqnufmm">Open Markets for Lightning Liquidity</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Jestopher</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header black'>
                <h3 className='agenda-stage__block__items__item__header__text'>17:15</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/jKSA7oJj27zF5NQnqnufmm?start=18m5s" target={'_blank'}>Building on a Bitcoin standard</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Steph</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
              <div className='agenda-stage__block__items__item__header green'>
                <h3 className='agenda-stage__block__items__item__header__text'>17:35</h3>
                <span className='agenda-stage__block__items__item__header__button'>Presentation</span>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>
                  <a href="https://bitcointv.com/w/cAjEnayhEiG8EpywVF5jsi" target={'_blank'}>Agora – Framework for Decentralized Communities</a>
                </p>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Matej Krejcirik</p>
              </div>
            </div>

            <div className='agenda-stage__block__items'>
            <div className={small ? 'agenda-stage__block__items__item__header blue' : 'agenda-stage__block__items__item__header blue last'}>
                <h3 className='agenda-stage__block__items__item__header__text'>17:55</h3>
              </div>
              <div className='agenda-stage__block__items__item__text'>
                <p className='agenda-stage__block__items__item__text__title'>Closing remarks</p>
              </div>
              <div className='agenda-stage__block__items__item__text last'>
                <p className='agenda-stage__block__items__item__text__title'>Lunaticoin</p>
              </div>
            </div>

        </div>
    </div>
    )
}

export default Sunday2nd
