import React from 'react'
import medias from '../../medias.json';
import './../Partners/style.scss'

const IMAGE_URI = '/images/medias/';

function Media() {

    let mediaItem = medias.map(media => {
        const url = IMAGE_URI + media.image;

        return (
            <div key={media.id} id={media.id}>
                <a href={media.url} target="_blank" key={media.id} id={media.id} className="partners__logo" rel="noreferrer">
                    <img src={url} alt={media.image} className="partner__image"/>
                </a>
            </div>
        )
    })
  return (
    <div className="partners__logos">
        {mediaItem}
    </div>
  )
}

export default Media
